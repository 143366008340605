.page h3 {
  @apply text-xl mt-6 py-3;
}

.page h4 {
  @apply mt-4;
}

.page p {
  @apply py-3;
}

.page a {
  @apply text-blue hover:text-blue-400 transition-colors duration-200;
}

.page ol {
  @apply list-decimal pl-4;
}

.page ul {
  @apply list-disc pl-4;
}

.page ol li {
  @apply pb-3;
}

.page table,
.page table td,
.page table th {
  @apply border;
}

.page table td,
.page table th {
  @apply p-2;
}
