.animate {
  will-change: opacity, transform;
  @apply opacity-0;
}

.animate-0 {
  animation: fadeInUp-sm 0.3s forwards;
}
.animate-1 {
  animation: fadeInUp-sm 0.3s forwards;
}
.animate-2 {
  animation: fadeInUp-sm 0.3s forwards 0.45s;
}
.animate-3 {
  animation: fadeInUp-sm 0.3s forwards 0.6s;
}
.animate-4 {
  animation: fadeInUp-sm 0.3s forwards 0.75s;
}
.animate-5 {
  animation: fadeInUp-sm 0.3s forwards 0.9s;
}
.animate-6 {
  animation: fadeInUp-sm 0.3s forwards 1.05s;
}

@keyframes fadeInUp-sm {
  from {
    opacity: 0;
    transform: translate3d(0, 30px, -20px);
  }

  to {
    transform: perspective(1000px) translate3d(0, 0, 0);
    opacity: 1;
  }
}

@keyframes fadeInUp-md {
  from {
    opacity: 0;
    transform: translate3d(0, 45px, -10px);
  }

  to {
    transform: perspective(1000px) translate3d(0, 0, 0);
    opacity: 1;
  }
}
