@import './page.css';
@import './task.css';
@import './animations.css';
@import './vendors/glide.css';

html,
body,
#root {
  @apply font-sans text-grey-900 min-h-screen;
}

*:focus {
  outline: none !important;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  @apply m-0 p-0 font-montserrat text-grey-900;
}

h1 {
  @apply text-3xl md:text-4xl mb-4;
}

h2 {
  @apply text-2xl mb-4;
}

.ul-list {
  @apply list-disc pl-6;
}

.ul-list li {
  @apply mb-2;
}

p {
  @apply text-grey-500;
}
